<template>
  <div class="addSensitiveWords">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
    >
      <el-form-item
        label="新增敏感词"
        :rules="[{ required: true, message: '新增敏感词', trigger: 'blur' }]"
        prop="sensitiveWords"
      >
        <v-input
          type="textarea"
          placeholder="敏感词"
          :width="310"
          :maxlength="10000"
          class="item-input"
          v-model="sensitiveWordsTxt"
        ></v-input>
        <span class="remark"
          >备注：单条敏感词长度2-20位，支持中英文数字，支持添加多条敏感词，以英文逗号隔开</span
        >
      </el-form-item>
      <el-form-item
        label="过滤原因"
        :rules="[{ required: true, message: '过滤原因', trigger: 'blur' }]"
        prop="reason"
      >
        <v-select clearable :options="wordsOptions" v-model="form.reason" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { mobileRegular } from "@/utils/regular";
import { addWordsUrl, reasonsListUrl } from "./api.js";

export default {
  name: "addSensitiveWords",
  data() {
    return {
      wordsOptions: [],
      width: 250,
      submitConfig: {
        queryUrl: "",
        submitUrl: addWordsUrl,
      },
      sensitiveWordsTxt: "",
      form: {
        sensitiveWords: [],
        reason: "",
      },
    };
  },
  watch: {
    sensitiveWordsTxt(value) {
      if (value) {
        let arr = value.split(",");
        this.form.sensitiveWords = arr;
      } else {
        this.form.sensitiveWords = [];
      }
    },
  },
  computed: {},
  mounted() {
    const { id } = this.$route.query;

    if (id !== undefined) {
      this.$refs.formPanel.getData({ scheduleId: id });
    }
    this.getReasons();
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    getReasons() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.$axios.get(`${reasonsListUrl}`, params).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.sensitiveWordReasonList) {
            res.data.sensitiveWordReasonList.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictKey;
              obj.value = ele.dictValue;
              this.wordsOptions.push(obj);
            });
          }
        }
      });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.addSensitiveWords {
  box-sizing: border-box;
  height: 100%;
  /deep/ textarea {
    height: 150px;
  }
  .remark {
    margin-left: 10px;
  }
}
</style>
